@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
/* @import "/public/assets/fonts/trajan-pro/Trajan-Pro.ttf"; */
@font-face {
  font-family: "trajan";
  src: url("/public//assets//fonts/trajan-pro/Trajan-Pro.ttf") format("ttf");
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cc9629;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b2811e;
}

.font-trajan {
  font-family: "trajan";
}
body {
  font-family: "Poppins", sans-serif;
  background-color: #15171e;
}
a {
  color: #257ad0;
}
.bg-dark-menu {
  background-color: #111111;
}
.main-logo {
  max-width: 90px;
  width: 100%;
}
.nav-item {
  color: #7d7d7e;
  font-size: 18px;
  font-weight: 400;
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.nav-item a:hover {
  color: #fff;
}
.nav-item a.active-menu {
  color: #fff;
}

.bg-video-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 98px);
  background: #111111 no-repeat center center/cover;
}
video {
  width: 100%;
  min-height: calc(100vh - 98px);
  z-index: 1;
}
.banner-title-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  z-index: 3;
}
.video-bannner-buycoin-btn {
  margin-top: 27%;
}
.banner-title {
  color: #fff;
  font-size: 60px;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
  z-index: 111;
}
.banner-title.banner-title_2 {
  margin-bottom: 0 !important;
}
/* .banner-title_2 {
  font-size: 40px !important;
} */
.banner-desc {
  color: #c4c4c4;
  font-size: 18px;
  font-weight: 400;
}
.btn-primary-cs {
  padding: 16px 41px;
  color: #99ccff;
  font-size: 18px;
  border-radius: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border: none;
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.75);
}
.btn-download-cs {
  padding: 16px 28px;
  color: #99ccff;
  font-size: 18px;
  border-radius: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 200px;
  height: 66px;
}
.btn-primary-bg {
  background-image: url("../public/assets/images/btn-primary_03.png");
}
.btn-download-bg {
  background-image: url("../public/assets/images/download-game-bg.png");
}
.btn-warning-bg {
  background-image: url("../public/assets/images/btn-warning-bg_03.png");
  color: #ffdd99 !important;
}
.btn-primary-cs:hover {
  color: #c4c4c4;
  border: none;
  outline: none;
  filter: contrast(0.9);
  cursor: pointer !important;
}
.btn-download-cs:hover,
.btn-download-cs:focus {
  color: #c4c4c4;
  border: unset;
  outline: unset;
  filter: contrast(0.9);
  cursor: pointer !important;
}
.play-game-link {
  color: #c4c4c4;
  font-size: 20px;
  font-weight: 400;
}
.download-play-section {
  /* background-image: url("/public/assets/images/Layer\ 34.png"); */
  position: relative;
  width: 100%;
  overflow: hidden;
}
.download-play-section-banner {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.character-postion {
  position: relative;
}
.download-area-imgs {
  padding: 150px 3% 2% 3%;
}
.char-2 {
  left: 250px;
}
.char-1 {
  right: 200px;
}
.char-3 {
  right: 50px;
  width: 110%;
  top: 24px;
  z-index: 1;
}
.download-play-title {
  text-align: center;
}
.download-play-section-up {
  position: relative;
  top: -70px;
}
.slide-style {
  padding: 8px;
}
.shadow {
  position: absolute;
  z-index: 1;
}
.shadow-left {
  left: 0;
}
.shadow-right {
  right: 0;
}
.coin-banner {
  background-image: url("/public/assets/images/coin-banner-bg.png");
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-position: center center;
  padding-top: 5rem;
  padding-bottom: 5rem;
  margin-top: -16px;
}
.about-wgc {
  background-image: url("/public/assets/images/about-wgc.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  position: relative;
}
.py-10 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.home-contact-section {
  background-image: url("/public/assets/images/new-footer-images.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.text-left {
  text-align: left !important;
}
ul {
  padding: 0;
}
ul li {
  list-style: none;
}
.home-contact-section {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.footer-section {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: #15171e;
}
.form-control-cs {
  border-radius: 0;
  padding-left: 1rem;
}
.form-control-cs::placeholder {
  color: #c4c4c4;
}
.link-color {
  color: #2268b0;
}
.footer-menu-section ul {
  display: flex;
  justify-content: center;
}
.footer-menu-section ul li {
  padding: 4px 16px;
  position: relative;
}
.footer-menu-section ul li a {
  text-decoration: none;
  color: #d3d3d3;
  font-weight: 400;
}
.footer-menu-section ul li:before {
  content: "";
  height: 16px;
  width: 2px;
  background-color: #d3d3d3;
  display: block;
  position: absolute;
  right: 0;
  top: 9px;
}

.footer-menu-section ul li:last-child:before {
  content: unset;
}
.copy-write {
  color: #45474d;
  font-weight: 500;
  font-size: 20px;
}
.btn-dark-cs {
  background-color: #15171e;
  padding: 8px 12px;
  padding: 12px 31px;
  color: #ffffff;
  font-size: 20px;
  border: 2px solid #15171e;
  border-radius: 0;
}
.btn-login {
  background-color: #282828;
  border-radius: 0;
  color: #ffffff;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
}
.btn-login:hover {
  border: 1px solid #257ad0;
  color: #fff;
}
.char-2 img,
.char-1 img {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
.char-2 img,
.char-1 img {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50px);
  }
}

.char-3 img {
  -webkit-animation: char-3 1.2s infinite alternate;
  animation: char-3 1.2s infinite alternate;
}

.char-3 img {
  -webkit-animation: char-3 1.2s infinite alternate;
  animation: char-3 1.2s infinite alternate;
}
@-webkit-keyframes char-3 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-60px);
  }
}
@keyframes char-3 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-60px);
  }
}
.fire-flame {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.35;
  left: 0;
  overflow: hidden;
}
.about-right-area {
  z-index: 1;
}
.line-progress {
  width: 100%;
}
.line-progress li,
.dot-line {
  position: relative;
  width: 100%;
  z-index: 1;
}
.line-progress li .dot-line::before {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #cc9629;
  position: absolute;
  top: 12px;
  left: 0;
  z-index: -1;
}

.progress-text {
  color: #8f7542;
  font-size: 12px;
}
.pro-img {
  height: 60px;
  position: relative;
}
.circle-area {
  width: 500px;
  height: 500px;
  border: 3px solid #cc9629;
  border-radius: 50%;
  background-color: #15171e;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dot-line {
  color: #cc9629;
}
.coin-progress-container {
  padding-right: 5%;
  display: flex;
}
.coin-1 {
  position: absolute;
  position: absolute;
  top: 12%;
  left: 34px;
  width: 65px;
}
.coin-2 {
  position: absolute;
  position: absolute;
  bottom: 20%;
  right: 0px;
  width: 65px;
}
.dot-line svg:hover {
  cursor: pointer;
}
.progres-left {
  width: 100%;
  margin-top: -30px;
}
.coin-pack-section {
  padding-left: 7%;
}
/* .game-slider-wrapper {
  position: relative;
} */
.dot-line svg:hover {
  border: 3px solid;
  border-radius: 50%;
}
.border-class-dot svg {
  border: 3px solid;
  border-radius: 50%;
}

.line-progress li .border-class-dot::after {
  content: "";
  width: 2px;
  height: 15px;
  background-color: #cc9629;
  position: absolute;
  top: -5px;
  left: 0;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, -50%);
}
.game-slider-wrapper {
  position: relative;
}
/* .game-slider-wrapper::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  left: 0;
  z-index: 1;
  top: 0;
}
.game-slider-wrapper::after {
  content: "";

  position: absolute;
  width: 0;
  height: 100%;
  right: 0;
  z-index: 1;
  top: 0;
} */
.selected-circle-ring {
  position: absolute;
  top: -25px;
  left: 49px;
  width: 57px;
}
.slick-slide img {
  border: 4px solid transparent;
  width: 100%;
  height: 100%;
  max-height: 200px;
  object-fit: cover;
}
.slick-slide img:hover {
  border: 4px solid #257ad0;
}
.nav-item a {
  position: relative;
}
.nav-item a:hover::after {
  content: ".";
  position: absolute;
  font-size: 40px;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 32px;
  line-height: 10px;
}
.nav-item.active-menu a::after {
  content: ".";
  position: absolute;
  font-size: 40px;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 32px;
  line-height: 10px;
}
.circle-area::before {
  content: "";
  width: 3px;
  height: 100px;
}
.char-1 img {
  min-width: 320px;
  width: 100%;
}
.char-2 img {
  min-width: 320px;
  width: 100%;
}
.char-3 img {
  min-width: 550px;
  width: 100%;
}
.d-none-cus {
  display: none;
}
.buy-coin-hide {
  display: block;
}
/* About style  */
.about-banner {
  background-image: url("../public/assets/images/banner\ above.png");
  width: 100%;
  /* height: 500px; */
  background-repeat: no-repeat;
  background-position: right center;
  background-size: inherit;
  /* min-height: 100vh; */
  min-height: calc(100vh - 98px);
  display: flex;
  align-items: center;
  padding-top: 5%;
  padding-bottom: 5%;
  background-color: #15171e;
}
.qa-banner {
  background-image: url("../public/assets/images/communication.png");
  width: 100%;
  /* height: 500px; */
  background-repeat: no-repeat;
  background-position: center right;
  background-size: inherit;
  /* min-height: 100vh; */
  min-height: calc(100vh - 148px);
  display: flex;
  align-items: center;
  padding-top: 5%;
  padding-bottom: 5%;
  background-color: #15171e;
}
.buy-coins-banner {
  background-image: url("../public/assets/images/buy-coins.png");
  width: 100%;
  /* height: 500px; */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /* min-height: 100vh; */
  min-height: calc(100vh - 98px);
  display: flex;
  align-items: center;
  padding-top: 5%;
  padding-bottom: 0%;
  background-color: #15171e;
  display: flex;
  align-items: flex-end;
  text-align: center;
  justify-content: center;
}
.prifile-banner {
  background-image: url("../public/assets/images/profile-banner.png");
  width: 100%;
  /* height: 500px; */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /* min-height: 100vh; */
  min-height: calc(100vh - 98px);
  display: flex;
  align-items: center;
  padding-top: 5%;
  padding-bottom: 0%;
  background-color: #15171e;
  display: flex;
  align-items: flex-end;
  text-align: center;
  justify-content: center;
}
.career-banner {
  background-image: url("../public/assets/images/career-banner.png");
  width: 100%;
  /* height: 500px; */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /* min-height: 100vh; */
  min-height: calc(100vh - 98px);
  display: flex;
  align-items: center;
  padding-top: 5%;
  padding-bottom: 0%;
  background-color: #15171e;
  display: flex;
  align-items: flex-end;
  text-align: center;
  justify-content: center;
}
.affilate-banner {
  background-image: url("../public/assets/images/affilate-banner2.png");
  width: 100%;
  /* height: 500px; */
  background-repeat: no-repeat;
  background-position: right center;
  background-size: inherit;
  /* min-height: 100vh; */
  min-height: calc(100vh - 98px);
  display: flex;
  align-items: center;
  padding-top: 5%;
  padding-bottom: 0%;
  background-color: #15171e;
  display: flex;
  align-items: flex-end;
  text-align: center;
  justify-content: center;
}
.affiliate-dashboard-banner {
  background-image: url("../public/assets/images/affiliate-dashboard-banner.png");
  width: 100%;
  /* height: 500px; */
  background-repeat: no-repeat;
  background-position: right center;
  background-size: inherit;
  /* min-height: 100vh; */
  min-height: calc(100vh - 98px);
  display: flex;
  align-items: center;
  padding-top: 10%;
  padding-bottom: 0%;
  background-color: #15171e;
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: center;
}
.banner-title-border {
  position: relative;
}
.banner-title-border::after {
  content: "";
  width: 150px;
  height: 4px;
  position: absolute;
  background-color: #257ad0;
  left: 16px;
  bottom: -10px;
}
.color-grey {
  color: #7d7d7e;
}
.padding-right-25 {
  padding-right: 25%;
}
.padding-btn {
  padding: 16px 34px;
}
.nav-tabs {
  border-bottom: none !important;
}
.nav-tabs .nav-item {
  border-bottom: 1px !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #1d1f24;
  border-radius: 0;
  border-color: #1d1f24;
  color: #fff;
  /* margin-top: 0px; */
}
.nav-tabs .nav-link {
  color: #32353a;
  background: #141414;
  border-radius: 0 !important;
}
.tabs-section {
  /* background-color: #1d1f24; */
}
.tab-content {
  background-color: #1d1f24;
  border-top: 4px solid #292c31 !important;
  padding-top: 5%;
  padding-bottom: 5%;
}
.tabs-section-content-list li {
  margin-bottom: 12px;
}
.tabs-section-content-list li:last-child {
  margin-bottom: 0px;
}
.accordion-item {
  margin-bottom: 12px;
  border-radius: 0 !important;
  border: none !important;
}
.accordion-button {
  background-color: #2b2d32;
  color: #fff;
  border-radius: 0 !important;
}
.accordion-header {
  border-radius: 0 !important;
}
.accordion-body {
  background-color: transparent !important;
}
.accordion {
  background-color: transparent !important;
  color: #fff;
}
.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #2b2d32 !important;
}
.accordion-item {
  background-color: transparent !important;
  border: none !important;
  color: #c4c4c4;
}
.accordion-button::after {
  background-image: url("../public/assets/icons/plus-icon.png") !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("../public/assets/icons/minus-icon.png") !important;
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button:focus {
  z-index: 3;
  border-color: none;
  outline: 0;
  box-shadow: none !important;
}
/* Games section style  */
.game-img-hover img {
  border: 4px solid transparent;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 1);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 1);
}
.game-img-hover:hover img {
  border: 4px solid #257ad0;
}
.cs-search-input {
  background-color: transparent;
  border: none !important;
  border-bottom: 1px solid #383838 !important;
  max-width: 300px;
  width: 100%;
  border-radius: 0px !important;
  position: relative;
  color: #383838;
  padding: 0;
}
.p-relative {
  position: relative;
}
.search-icon-position {
  position: absolute;
  top: -3px;
  right: 7px;
  color: #383838;
}
.cs-search-input:focus {
  color: #383838;
  background-color: transparent;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}
.cs-search-input::placeholder {
  color: #383838;
}
.games-section {
  padding-top: 4rem;
  padding-bottom: 4rem;
  position: relative;
}
.border-center::after {
  left: 50%;
  transform: translate(-50%, -50%);
}
.shadow-img {
  position: absolute;
  z-index: 1;
  width: 100%;
  bottom: 0;
}
.main-pages-banner {
  min-height: 100vh;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}
.title-strike {
  position: absolute;
  top: 12%;
  max-width: 250px;
  border: solid 1.5em rgba(#000, 0.2);
  /* background: url(oranges.jpg) 50% / cover border-box padding-box; */
  display: none;
}
.beta-game {
  position: absolute;
  bottom: -14%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
}
.main-banner-game-insight {
  /* min-height: 75vh; */
  object-fit: contain;
}
.buy-game-section {
  padding-top: 4rem;
  padding-bottom: 4rem;
  position: relative;
}
.text-warning {
  color: #cc9629 !important;
}
.download-os-btn {
  display: flex;
}
.download-os-btn button {
  background-color: transparent !important;
  border: 0;
  width: 60px;
  font-size: 12px;
  color: #257ad0;
  text-decoration: underline;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.download-os-btn button span {
  height: 40px !important;
  margin-bottom: 4px;
}
/* .shadow-cs::after {
  content: "";
  background-image: url("../public/assets/images/shadow-right-mini.png");
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  z-index: 9999;
  top: 0;
} */
.shadow-cs {
  position: relative;
}
.shadow-right-mini {
  position: absolute;
  /* top: 0; */
  right: 0;
  z-index: 1;
}
.shadow-left-mini {
  position: absolute;
  /* top: 0; */
  left: 0;
  z-index: 1;
}
.text-title-border::after {
  content: "";
  width: 80px;
  height: 3px;
  position: absolute;
  background-color: #257ad0;
  left: 3px;
  bottom: -15px;
}
.modal-content {
  background-color: #282828;
  border-radius: 0 !important;
}
.text-light {
  color: #c4c4c4 !important;
}
.btn-dark {
  background-color: #15171e;
}

.login-reset {
  font-size: 16px;
  color: #737373;
  font-weight: 400;
}
.text-Link-cs {
  cursor: pointer;
  text-decoration: underline;
}
.btn-close {
  color: #737373 !important;
  filter: contrast(0.3);
}
.login-heading {
  font-size: 2.5rem !important;
}
.buy-coin-card {
  background-color: #282828;
  padding: 40px 16px 18px 16px;
}
.career-card {
  background-color: #282828;
  padding: 0px;
}
.buy-coin-inner-card {
  border: 3px solid #cc9629;
  padding: 8px;
  background-color: #15171e;
  position: relative;
}
.home-page-card-new {
  position: relative;
}
.badge-text {
  background-color: #cc9629;
  padding: 4px 8px;
  border-radius: 4px;
  width: fit-content;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  margin: 0 auto;
}
.badge-text span {
  font-size: 18px;
  font-weight: 600;
}
.bagde-area {
  text-align: center;
  position: relative;
  top: -30px;
}
.list-style-none {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}
.package-name {
  font-size: 14px;
  color: #8f7542;
  font-weight: 500;
}
.package-price {
  font-size: 24px;
  color: #fdfef4;
  font-weight: 600;
}
.buy-coin-package-img img {
  width: 140px;
  max-width: 100%;
  height: 170px !important;
  max-height: 100%;
  object-fit: contain;
}
.package-buy-small {
  font-size: 10px;
}
.w-250 {
  width: 250px;
}
.coming-soon-title {
  color: #373737;
  letter-spacing: 3px;
}
.why-wgc-banner {
  background-image: url("../public/assets/images/why-wgc-banner.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.h4-cs-style {
  color: #fff;
  font-size: 32px !important;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 24px;
  position: relative;
}
.steps-border::after {
  content: "";
  width: 100px;
  height: 3px;
  position: absolute;
  background-color: #257ad0;
  left: 2px;
  bottom: -8px;
}
.dot-color {
  color: #257ad0;
}
.step-text-list {
  display: flex;
  align-items: baseline;
}
.flex-cs {
  /* display: flex;
  align-items: stretch; */
  /* min-height: 160px; */
  flex: 1;
  margin-bottom: 16px;
}
.buy-coins-wrapper {
  margin-bottom: 4rem;
}
.buy-coins-why-wgc,
.how-buy-wgc-wrapper {
  margin-bottom: 4rem;
}
.hover-primary:hover {
  border-color: #267ad0 !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px;
}
.best-moba-League-of-Legends-banner {
  background-image: url("../public/assets/images/Best-MOBA-League-of-Legends.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 4rem;
  /* padding-bottom: 4rem; */
}
.join-affiliate-program-wrapper {
  background-image: url("../public/assets/images/join-affiliate-program-bg.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 4rem;
  /* padding-bottom: 4rem; */
}
.fs-14 {
  font-size: 14px;
}
.btn-blue {
  background-color: #267ad0;
  border: 1px solid #267ad0;
  color: #fff;
  border-radius: 0;
  padding: 12px 16px;
}
.btn-blue:hover {
  background-color: #104c89;
  border: 1px solid #104c89;
  color: #ffffff;
}
.css-1s2u09g-control,
.css-1pahdxg-control {
  border-color: #fff !important;
  border-radius: 0% !important;
  min-height: 50px !important;
}
.css-1s2u09g-control:hover,
.css-1pahdxg-control:hover {
  border-color: #fff !important;
  border-radius: 0% !important;
  min-height: 50px !important;
}
.form-label-cs {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 6px;
}
.card-title {
  font-size: 16px;
  color: #ffffff;
}
.job-title {
  font-size: 28px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 500;
}
.job-type {
  font-size: 18px;
}
.job-type small {
  font-size: 10px !important;
}
.dot-icon-sizing {
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.step-text-list-download {
  align-items: center !important;
  margin-bottom: 8px !important;
}
.downlaod-count-warpper {
  display: flex;
  background: transparent;
  width: 160px;
  padding: 12px;
  height: 160px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 50%;
  /* border: 3px solid #000000; */
  margin: auto;
  position: relative;
  color: #a3eb00;
  margin: 20px auto;
  border: 4px solid #000000;
  border-left: 4px solid #a3eb00;
  /*     border-right: 5px solid #F00; */
  /*     animation: spinner 1s linear infinite forwards; */
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
/* .downlaod-count-warpper::after {
  content: "";
  background-color: transparent;
  border: 3px solid white;
  width: 160px;
  height: 160px;
  position: absolute;
  border-radius: 50%;
  border-width: 4px;
} */
.wgc-card {
  background: rgb(37, 122, 208);
  background: linear-gradient(
    90deg,
    rgba(37, 122, 208, 1) 0%,
    rgba(2, 74, 166, 1) 100%
  );
  color: #ffffff;
  min-width: 240px;
}
.profile-img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.h-74 {
  height: 74px;
}
.card-gold-header {
  background-color: #cc9629;
  padding: 12px 16px;
}
.card-title-gold h2 {
  font-size: 24px;
  color: #15171e;
  font-weight: 600;
}
.btn-outline-gold {
  background-color: transparent;
  border: 2px solid #15171e;
  border-radius: 0% !important;
}
.btn-search:hover {
  border: transparent !important;
}
.gold-input input.form-control,
.gold-input .css-1s2u09g-control {
  background-color: transparent !important;
  color: #fff !important;
  border: 2px solid #cc9629;
  border-radius: 0% !important;
  height: 50px !important;
}
.gold-input .css-1s2u09g-control,
.gold-input .css-1pahdxg-control {
  border-color: #cc9629 !important;
}
.gold-input .css-14el2xx-placeholder {
  color: #fff !important;
}
.gold-input label {
  font-size: 16px;
  font-weight: 500;
  color: #cc9629;
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.gold-table .tbody,
.gold-table td,
.gold-table tfoot,
.gold-table th,
.gold-table thead,
.gold-table tr {
  border-color: #cc9629;
}
.gold-table tr th {
  background-color: #cc9629;
  border-color: #15171e;
  color: #15171e;
  font-weight: 600 !important;
}
.gold-table td {
  color: #b4b4b4 !important;
}
.gold-table tr th:first-child {
  border-left-color: #cc9629;
}
.gold-table tr th:last-child {
  border-right-color: #cc9629;
}
.gold-table tbody {
  max-height: 400px;
  overflow-y: auto;
}
.affiliatede-url-link {
  margin: 0;
  color: #ffffff;
  font-weight: 600;
  white-space: nowrap;
  /* width: 50px;  */
  overflow: hidden;
  text-overflow: ellipsis;
}
.affiliatede-url-link-wrapper {
  width: 250px;
  padding: 8px 16px;
  /* margin: auto; */
  border: 1px solid #257ad0;
  white-space: nowrap;
  /* width: 50px;  */
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-copy-cs {
  color: #767676;
}
.btn-copy-cs:hover {
  color: #cc9629;
  border: 0 !important;
}
.user-profile-icon-wrapper {
  background-color: #282828;
  padding: 4px;
  border-radius: 50%;
  width: fit-content;
  margin: auto;
  position: relative;
}
.btn-edit {
  position: absolute;
  background: #257ad0;
  width: 32px;
  height: 33px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  right: 0;
  color: #ffffff;
  font-size: 24px;
}
.btn-edit:hover {
  color: #ffffff;
}
.user-name-show {
  background-color: #282828;
  color: #ffffff;
  width: fit-content;
  margin: auto;
  padding: 6px 12px;
}
.tabs-margin-cs {
  margin-bottom: 1px !important;
}
.tabs-margin-cs .nav-item {
  padding: 0px !important;
}
.img-rounded {
  border-radius: 50%;
}
/* .text-blue {
  color: red;
} */
/* responsive style  */

@media screen and (max-width: 1080px) {
  .char-3 {
    right: 17%;
  }
  .char-2 {
    left: 8%;
  }
  .char-1 {
    right: 30%;
  }
}
@media screen and (max-width: 1220px) {
  .char-3 {
    right: 10%;
  }
  .char-2 {
    left: 12%;
  }
  .char-1 {
    right: 20%;
  }
}
@media screen and (max-width: 1080px) {
  .char-3 {
    right: 17%;
  }
  .char-2 {
    left: 8%;
  }
  .char-1 {
    right: 30%;
  }
}
@media screen and (max-width: 992px) {
  .d-none-cus {
    display: block;
    padding-left: 0%;
    text-align: center;
  }
  .beta-game {
    max-width: 200px;
  }
  .buy-coin-hide {
    display: none;
  }
  .coin-progress-container {
    display: block;
    padding-right: 0%;
  }
  .circle-area {
    width: 650px;
    /* width: 100%; */
    height: 650px;
    margin: 0 auto;
    /* height: 100%; */
  }
  .char-1 {
    right: 55%;
  }
  .char-2 {
    left: 2%;
  }
  .char-3 {
    right: 36%;
  }
  .banner-title-wrapper {
    top: 50%;
  }
  .video-bannner-buycoin-btn {
    margin-top: 38%;
  }
  .banner-title_2 {
    margin-bottom: 4px !important;
    font-size: 50px;
  }
}
@media screen and (max-width: 767px) {
  .w-50 {
    width: 100% !important;
  }
  .gold-input .form-group {
    width: 48% !important;
  }
  .bg-video-wrap {
    /* height: auto; */
  }
  .banner-title-wrapper {
    top: 50%;
  }
  .banner-title {
    font-size: 46px;
    font-weight: 600;
    margin-bottom: 8px !important;
  }
  .banner-desc {
    font-size: 18px;
    margin-bottom: 12px !important;
  }
  .banner-desc.video-banner-desc {
    margin-top: 36%;
  }
  .video-bannner-buycoin-btn {
    margin-top: 12%;
  }
  video {
    min-height: auto;
  }
  .coin-progress-container {
    display: block;
    padding-right: 0%;
  }
  .circle-area {
    width: 500px;
    /* width: 100%; */
    height: 500px;
    margin: 0 auto;
    /* height: 100%; */
  }
  .char-1 img {
    min-width: 300px;
    width: 100%;
  }
  .char-2 img {
    min-width: 300px;
    width: 100%;
  }
  .char-3 img {
    min-width: 500px;
    width: 100%;
  }
  .char-3 {
    right: 49%;
  }
  .char-1 {
    right: 70%;
  }
  .char-2 {
    left: 1%;
  }
}
@media screen and (max-width: 690px) {
  .banner-title-wrapper {
    top: 46%;
  }
  .banner-desc.video-banner-desc {
    margin-top: 35%;
  }
  .video-bannner-buycoin-btn {
    margin-top: 8%;
  }
}
@media screen and (max-width: 599px) {
  .contact-us-section-form {
    flex-direction: column;
  }
  .contact-us-section-form input {
    height: 58px;
    margin-bottom: 8px;
  }
  .bg-video-wrap {
    min-height: 100vh;
    height: 100%;
  }
  .banner-title-wrapper {
    top: 72%;
    text-align: center;
  }
  .banner-title {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 8px !important;
  }
  .banner-desc.video-banner-desc {
    margin-top: 0%;
  }
  .video-bannner-buycoin-btn {
    margin-top: 0%;
  }
  .banner-title.banner-title-border {
    margin-bottom: 24px !important;
  }

  .banner-desc {
    font-size: 16px;
    margin-bottom: 12px !important;
  }
  .btn-primary-cs {
    padding: 12px 30px;
    font-size: 16px;
  }
  .play-game-link {
    font-size: 16px;
  }
  video {
    min-height: auto;
  }
  .coin-progress-container {
    display: block;
    padding-right: 0%;
  }
  .circle-area {
    width: 420px;
    /* width: 100%; */
    height: 420px;
    margin: 0 auto;
    /* height: 100%; */
  }
  .char-1 img {
    min-width: 250px;
    width: 100%;
  }
  .char-2 img {
    min-width: 250px;
    width: 100%;
  }
  .char-3 img {
    min-width: 450px;
    width: 100%;
  }
  .char-3 {
    right: 49%;
  }
  .char-1 {
    right: 70%;
  }
  .char-2 {
    left: 1%;
  }
  .modal-body {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media screen and (max-width: 480px) {
  .gold-input .form-group {
    width: 100% !important;
    margin: 8px 0 !important;
  }
  .accordion-body {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .bg-video-wrap {
    height: 100vh;
    min-height: 100%;
  }
  .banner-title-wrapper {
    top: 55%;
    text-align: center;
  }

  .banner-title {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 24px !important;
  }
  .banner-desc {
    font-size: 12px;
    margin-bottom: 32px !important;
  }
  .video-banner-desc {
    margin-top: 10%;
  }
  .btn-primary-cs {
    padding: 8px 16px;
    font-size: 12px;
  }
  .play-game-link {
    font-size: 14px;
  }
  video {
    min-height: auto;
  }
  .coin-progress-container {
    display: block;
    padding-right: 0%;
  }
  .circle-area {
    width: 380px;
    /* width: 100%; */
    height: 380px;
    margin: 0 auto;
    /* height: 100%; */
  }
  .char-1 img {
    min-width: 200px;
    width: 100%;
  }
  .char-2 img {
    min-width: 200px;
    width: 100%;
  }
  .char-3 img {
    min-width: 350px;
    width: 100%;
  }
  .char-3 {
    right: 45%;
  }
  .char-1 {
    right: 70%;
  }
  .char-2 {
    left: 2%;
  }

  .padding-right-25 {
    padding-right: 0%;
  }
  .footer-menu-section ul {
    flex-direction: column;
  }
  .footer-menu-section ul li:before {
    display: none;
  }
  .w-250 {
    width: auto;
  }

  .buy-coin-btn {
    padding-top: 20px;
  }
}
@media screen and (max-width: 400px) {
  .tabs-margin-cs .nav-link,
  .tabs-margin-cs .nav-item {
    width: 100%;
  }
  .tabs-margin-cs {
    padding: 0;
  }
  .progress-text {
    font-size: 10px;
  }
  .circle-area {
    width: 290px;
    height: 290px;
    margin: 0 auto;
  }
  .active-icon {
    max-width: 30px;
    width: 100%;
  }
  .non-active-icon {
    max-width: 25px;
    width: 100%;
  }
  .char-1 img {
    min-width: 150px;
    width: 100%;
  }
  .char-2 img {
    min-width: 150px;
    width: 100%;
  }
  .char-3 img {
    min-width: 300px;
    width: 100%;
  }
  .char-3 {
    right: 140px;
  }
  .char-1 {
    right: 63%;
  }
  .char-2 {
    left: 2%;
  }
  .banner-title-wrapper {
    top: 50%;
  }
}
@media screen and (max-width: 300px) {
  .bg-video-wrap .banner-desc {
    display: none;
  }
  .char-3 img {
    min-width: 250px;
  }
  .char-2 img {
    min-width: 120px;
  }
  .char-1 img {
    min-width: 120px;
  }
  .char-2 {
    left: 0%;
  }
  .char-1 {
    right: 83%;
  }
  .char-3 {
    right: 150px;
  }
  .circle-area {
    width: 250px;
    height: 250px;
  }
  .coin-1 {
    top: 9%;
    left: -2px;
    width: 50px;
  }
  .coin-2 {
    right: -8px;
    width: 50px;
  }
  .about-right-area {
    text-align: center;
  }
}
.mb-6 {
  margin-bottom: 5rem;
}
.mb-7 {
  margin-bottom: 7rem;
}
.arrow-down img {
  animation: bounce 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
  cursor: pointer;
  /* height: 20px; */
}

@keyframes bounce {
  50% {
    transform: translateY(-15px);
  }
}
.py-6 {
  padding-top: 3rem;
  padding-bottom: 1rem;
}
.border-after-text::after {
  content: "";
  width: 30%;
  height: 4px;
  background-color: #491fc9;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: -20px;
}
.wgc-value {
  color: #ffffff;
  font-weight: 600;
  text-align: center;
  margin-top: 32px;
  font-size: 18px;
}
.title-with-arrow {
  color: #ffffff;
  font-weight: 600;
}
.arrow-size {
  color: #ffffff;
  font-weight: 600;
}

.carousel-root {
  width: 70% !important;
  margin: auto !important;
  margin-top: 3% !important;
}

.carousel .slide {
  /* background: #fff !important; */
  color: black;
  height: 100%;
}

.carousel .slide img.testi-user {
  width: 110px !important;
  border-radius: 50%;
  border: 4px solid #282828;
}

.myCarousel {
  background: #2b2d32;
  margin-top: -6%;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;

  height: 286px;
}

.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 5% !important;
}

.myCarousel h3 {
  color: #ffffff;
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 22px;
}

.myCarousel h4 {
  /* text-transform: uppercase; */
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: #ffffff;
  font-size: 16px;
}

.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #ffffff;
  font-size: 16px;
  font-family: sans-serif;
  /* max-height: 67px; */
}

.myCarousel p:before {
  content: "“";
  color: #ffffff;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.myCarousel p:after {
  content: "”";
  color: #ffffff;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #454545 !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: #000 !important;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

.App {
  text-align: center;
}
.carousel .control-dots .dot {
  border-radius: 0 !important;
  width: 12px !important;
  height: 12px !important;
}
.carousel .control-dots .dot.selected {
  background-color: #00bcff !important;
}
.carousel.carousel-slider .control-arrow {
  margin-top: 50px;
}
@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }
  .myCarousel {
    background: #2b2d32;
    margin-top: -9%;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;

    height: 269px;
  }

  .carousel .slide img {
    width: 24% !important;
    border-radius: 50%;
  }
}
.rating-img img {
  border-radius: unset !important;
  border: unset !important;
  width: 90px !important;
}
.btn-outline-warning {
  border: 1px solid #cc9629 !important;
  color: #cc9629 !important;
}
.btn-outline-warning:hover {
  color: #fff !important;
}
.bg-light-grey-cus {
  background-color: #282828;
  padding: 16px;
}
.tabs-section-content-list {
  text-align: left;
}
.apexcharts-title-text {
  fill: #7d7d7e;
}
.icon-style-msg {
  color: #373737;
}

.apexcharts-toolbar {
  display: none !important;
}
.alert-send-coins path {
  stroke: #cc9629;
}
.verify-kyc-link {
  cursor: pointer;
}
.MuiSvgIcon {
  fill: #2268b0 !important;
}
.MuiSvgIcon.Mui-active {
  fill: #cc9629 !important;
}
.wizard-input-label {
  font-size: 16px;
  font-weight: 400;
  color: #c4c4c4;
  margin-bottom: 12px;
}
.react-tel-input .form-control {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: unset !important;
  width: 100% !important;
  height: 48px !important;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.1);
}

.identity_clolum {
  align-items: center;
  border: 2px solid #c4c4c4;
  border-radius: 10px;
  display: flex;
  max-width: 450px;
  padding: 30px 20px;
  position: relative;
  width: 100%;
  height: 100%;
}
.identity_clolum input {
  -webkit-appearance: none;
  appearance: none;
  bottom: 0;
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.identity_clolum .upload_icon {
  display: inline-block;
  max-width: 100px;
  width: 100%;
}
.identity_clolum .upload_icon span {
  height: 100px;
  object-fit: cover;
}
.identity_clolum .upload_detail {
  padding-left: 10px;
  width: calc(100% - 100px);
}
.identity_clolum .upload_detail h4 {
  /* color: #cc9629; */
  letter-spacing: 0.9px;
  text-align: center;
  font-size: 20px;
}
.identity_clolum .upload_detail p {
  color: #fff;
  font: 400 14px Helvatica, sans-serif;
  letter-spacing: 0.6px;
  margin: 0 auto;
  max-width: 250px;
  padding-top: 6px;
  text-align: center;
  width: 100%;
}
.resend-code {
  font-size: 18px;
  cursor: pointer;
}
.apexcharts-canvas text {
  fill: #ffffff;
}
.apexcharts-legend-text {
  color: #ffffff !important;
}
@media only screen and (max-width: 480px) {
  .main-banner-game-insight {
    min-height: 100%;
    object-fit: cover;
  }
  .main-pages-banner {
    min-height: 100%;
  }
  .beta-game {
    position: absolute;
    bottom: -78%;
    width: 100px;
  }
}
@media only screen and (max-width: 380px) {
  .beta-game {
    width: 70px;
  }
}

.game-detail-main-img .slick-slide img {
  max-height: 500px;
  height: 100%;
  object-fit: scale-down;
}

.fab {
  background-color: transparent;
  height: 50px;
  width: 50px;
  border-radius: 32px;
  transition: height 300ms;
  transition-timing-function: ease;
  position: absolute;
  right: 50px;
  bottom: 50px;
  text-align: center;
  overflow: hidden;
}

.fab:hover {
  height: 200px;
}

.fab:hover .mainop {
  transform: rotate(225deg);
}

.mainop {
  margin: auto;
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 0;
  right: 0;
  transition: transform 300ms;
  background-color: #cc9629;
  border-radius: 50%;
  border: 3px solid #cc9629;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainopShadow {
  width: 50px;
  height: 50px;
  border-radius: 32px;
  position: absolute;
  right: 50px;
  bottom: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.mainop i {
  font-size: 24px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.minifab {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 24px;
  z-index: 5;
  float: left;
  margin-bottom: 8px;
  margin-left: 8px;
  margin-right: 8px;
  background-color: transparent;
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #cc9629;
  cursor: pointer;
}

.minifab:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.top-tip-join-now {
  display: none;
}
.top-tip-discord {
  display: none;
}
.minifab:hover + .top-tip-join-now {
  display: block;
  position: fixed;
  right: 100px;
  background-color: #cc9629;
  /* border-radius: 2px; */
  /* border: 2px solid #cc9629; */
  width: 120px;
  bottom: 163px;
  clip-path: polygon(0% 0%, 85% 0%, 100% 50%, 85% 100%, 0% 100%);
}
.minifab:hover + .top-tip-discord {
  display: block;
  position: fixed;
  right: 100px;
  background-color: #cc9629;
  /* border-radius: 2px; */
  /* border: 2px solid #cc9629; */
  width: 120px;
  /* bottom: 163px; */
  clip-path: polygon(0% 0%, 85% 0%, 100% 50%, 85% 100%, 0% 100%);
  bottom: 116px;
}
.minifabIcon {
  height: 24px;
  width: 24px;
  /* margin-top: 12px; */
}
/* 
.op1 {
  background-color: #2f82fc;
}

.op2 {
  background-color: #0f9d58;
}

.op3 {
  background-color: #fb0;
}

.op4 {
  background-color: #dd5145;
}

.op5 {
  background-color: #673ab7;
} */
.floating-button {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
.home-page-banner-card {
  max-width: 600px;
  margin: auto;
  margin-top: 120px;
}
.home-page-banner-card-inner {
  max-width: 143px;
}
.fw-600 {
  font-weight: 600;
}
@media only screen and (max-width: 768px) {
  .home-page-banner-card {
    display: none;
  }
}
.kyc-botton-setting {
  max-width: 300px;
  margin: auto;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #2e2b2d !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #4eb2ed !important;
}
.MuiStepConnector-horizontal {
  display: none !important;
}
.css-m5vj9m-MuiStepper-root {
  align-items: center !important;
  justify-content: center;
}
.text-secoundar-cs {
  color: #c4c4c4 !important;
}
.text-light-blue-cs {
  color: #4eb2ed !important;
}
.form-check-input:checked {
  background-color: #4eb2ed;
  border-color: #4eb2ed;
}
.btn-warning {
  background-color: #cc9629;
  border-color: #cc9629;
}

.user-profile-icon-wrapper #file-input {
  display: none;
}

.user-profile-icon-wrapper label[for="file-input"] * {
  vertical-align: middle;
  cursor: pointer;
}

.user-profile-icon-wrapper .file-input-label {
  vertical-align: middle;
  cursor: pointer;
  position: absolute;
  right: -10px;
  color: white;
  top: 20px;
  background: #257ad0;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.file-input-label svg {
  margin-left: 10px;
}

.user-profile-icon-wrapper label[for="file-input"] span {
  margin-left: 10px;
}
.qrCode-modal .modal-content {
  background: #ffffff;
}
.dropdown-toggle.btn.btn-primary {
  border: unset;
  background: unset;
}
.dropdown-toggle.btn.btn-primary:hover,
.dropdown-toggle.btn.btn-primary:focus {
  border: unset;
  background: unset;
  outline: unset;
  box-shadow: unset;
}
.dropdown-menu.show {
  padding: 0;
  background: #111111;
  border-radius: 0;
  border: unset;
}
.dropdown-item {
  border-bottom: 1px solid #c4c4c4;
  background: #111111;
  color: #c4c4c4;
}
